<template>
  <div></div>
</template>
<script lang="ts">
import { Options, Prop, Vue } from "vue-property-decorator";

@Options({
  name: "Administration",
})
export default class Administration extends Vue {}
</script>

<style lang="less">
</style>